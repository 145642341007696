import { IPaginationDTO } from 'modules/inbound/shared/domain/IPagination';

export interface UpcomingTransitsItemDTO {
  provider_id: string;
  plate_number: string;
  delivery_datetime: string;
  count_label_container: number;
}

class UpcomingTransitsDTO {
  data: UpcomingTransitsItemDTO[];
  pagination: IPaginationDTO;

  constructor({ data, pagination }) {
    this.data = data;
    this.pagination = pagination;
  }
}

export default UpcomingTransitsDTO;
